import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify/dist/index';
import 'react-toastify/dist/ReactToastify.css';
import './assets/custom-toastify.scss';

function notifySuc(msg) {
    toast.success(msg, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: 'toast-box',
    });
}

function notifyErr(msg) {
    toast.error(msg, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: 'toast-box',
    });
}

class Toast extends Component {
    render(){
        return (
            <React.Fragment>
                <ToastContainer
                    className={"toastity-container"}
                    position="bottom-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </React.Fragment>
        );
    }
}


if (document.getElementById('toast-container')) {
    ReactDOM.render(<Toast />, document.getElementById('toast-container'));
}

window.notify = function notify($type = 'success', $msg=null) {
    switch($type) {
        case 'error':
            notifyErr($msg);
            break;

        default:
            notifySuc($msg);
    }
};
